import { useEffect, useState } from "react";

import { getCurrentUserFields } from "../../api/utils/getAccessToken";

/**
 * This hook determines whether the current tenant id is included in the set of tenant ids given
 * @returns A boolean indicating whether the current tenant is included
 */
const useDoesEmailMatch = (emailsToMatch: Set<string>) => {
  const [currentEmail, setCurrentEmail] = useState<string>();

  useEffect(() => {
    void (async () => {
      const userDetails = await getCurrentUserFields();
      setCurrentEmail(userDetails.email);
    })();
  });

  return emailsToMatch.has(currentEmail ?? "");
};

export default useDoesEmailMatch;
