/**
 * Handles any error that may be encountered when interacting with web
 * storage. This most often occurs in Safari under certain browser
 * privacy configurations (for example, when cookies are disabled).
 */
const handleError = <T extends unknown[], U>(
  fn: (...args: T) => U,
  valueToReturnInCaseOfError: U
) => {
  return (...args: T): U => {
    try {
      return fn(...args);
    } catch {
      return valueToReturnInCaseOfError;
    }
  };
};

export default {
  local: {
    getItem: handleError((key: string) => {
      return localStorage.getItem(key);
    }, undefined),
    setItem: handleError((key: string, value: string) => {
      return localStorage.setItem(key, value);
    }, undefined),
    removeItem: handleError((key: string) => {
      return localStorage.removeItem(key);
    }, undefined),
    clear: handleError(() => {
      return localStorage.clear();
    }, undefined),
  },
};
