import { ContentSpinner } from "@taxbit-private/cosmic";
import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import styled from "styled-components";

import { FileRequestSourceType } from "./api/endpoints/sources/subpoenaApiTypes";
import RequireAuth from "./auth/RequireAuth";
import AppNavigation from "./components/AppNavigation";
import CiTermsOfUseWrapper from "./components/CiTermsOfUseWrapper";
import RouteComponent from "./components/RouteComponent";
import Shell from "./components/Shell";
import CaseFilterQueryParamsCacheProvider from "./utils/CaseFilterQueryParamsCacheProvider";
import { useFlags } from "./utils/hooks/useFlags";

const Unauthorized = lazy(() => import("./pages/error/Unauthorized.page"));
const NotFound = lazy(() => import("./pages/error/NotFound.page"));
const ErrorPage = lazy(() => import("./pages/error/Error.page"));

const CaseList = lazy(() => import("./pages/case-list/CaseList.page"));
const AddSource = lazy(() => import("./pages/add-source-view/AddSource.page"));
const AssetSearch = lazy(
  () => import("./pages/asset-search-view/AssetSearch.page")
);
const ColumnFormattingErrors = lazy(
  () => import("./pages/add-source-view/ColumnFormattingErrors.page")
);
const ViewWalletErrors = lazy(
  () => import("./pages/add-source-view/public-address/ViewWalletErrors.page")
);

const ReportList = lazy(() => import("./pages/report-list/ReportList.page"));
const CaseSummaryView = lazy(
  () => import("./pages/case-summary-view/CaseSummary.page")
);
const CaseSettingView = lazy(
  () => import("./pages/case-setting-view/CaseSettings.page")
);

const LinkTransactions = lazy(
  () => import("./pages/link-transactions/LinkTransactions.page")
);
const SourceList = lazy(() => import("./pages/source-view/SourceList"));
const SourcePage = lazy(() => import("./pages/source-view/Source.page"));
const TransactionDetails = lazy(
  () => import("./pages/transaction-detail-view/TransactionDetails.page")
);
const GenericCsv = lazy(() => import("./pages/add-source-view/GenericCsv"));
const JsonLFile = lazy(() => import("./pages/add-source-view/JsonLFile"));
const PublicAddress = lazy(
  () => import("./pages/add-source-view/public-address/PublicAddress")
);
const CaseIndexPage = lazy(() => import("./pages/case-default/CaseIndexPage"));
const TransactionList = lazy(
  () => import("./pages/transaction-list/TransactionList.page")
);
const SourceErrors = lazy(
  () => import("./pages/source-detail-view/SourceErrors.page")
);
const SourceOption = lazy(() => import("./pages/source-view/SourceOption"));

const Developer = lazy(() => import("./pages/developer/Developer"));

const FileConverterUploadPage = lazy(
  () => import("./pages/file-conversions-view/FileConversionsUploader")
);

const FileConverterReviewPage = lazy(
  () => import("./pages/file-conversions-view/FileConversionsReview")
);

export const App = () => {
  const {
    isCaseSummaryEnabled,
    shouldShowJSONLUpload,
    isWalletUploadEnabled,
    isFileConverterEnabled,
  } = useFlags();

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <RequireAuth>
          <Shell />
        </RequireAuth>
      ),
      errorElement: (
        <RouteComponent>
          <ErrorPage />
        </RouteComponent>
      ),
      children: [
        {
          path: "unauthorized",
          element: (
            <RouteComponent>
              <Unauthorized />
            </RouteComponent>
          ),
        },
        {
          index: true,
          element: <Navigate to="case-list" />,
          errorElement: (
            <RouteComponent>
              <ErrorPage />
            </RouteComponent>
          ),
        },
        {
          path: "case-list",
          element: <CaseList />,
          errorElement: (
            <RouteComponent>
              <ErrorPage />
            </RouteComponent>
          ),
        },
        {
          path: "developer",
          element: <Developer />,
          errorElement: (
            <RouteComponent>
              <ErrorPage />
            </RouteComponent>
          ),
        },
        {
          path: "case-list/:caseId",
          element: <AppNavigation />,
          errorElement: (
            <RouteComponent>
              <ErrorPage />
            </RouteComponent>
          ),
          children: [
            {
              index: true,
              element: (
                <RouteComponent>
                  <CaseIndexPage />
                </RouteComponent>
              ),
            },
            {
              path: "report-list",
              element: (
                <RouteComponent>
                  <ReportList />
                </RouteComponent>
              ),
            },
            ...(isCaseSummaryEnabled
              ? [
                  {
                    path: "case-summary",
                    element: (
                      <RouteComponent>
                        <CaseSummaryView />
                      </RouteComponent>
                    ),
                  },
                ]
              : []),
            {
              path: "transactions",
              element: (
                <RouteComponent>
                  <TransactionList />
                </RouteComponent>
              ),
            },
            {
              path: "transactions/:transactionId",
              element: (
                <RouteComponent>
                  <TransactionDetails />
                </RouteComponent>
              ),
            },
            {
              path: "transactions/:transactionId/edit",
              element: (
                <RouteComponent>
                  <TransactionDetails />
                </RouteComponent>
              ),
            },
            {
              path: "transactions/:transactionId/assets",
              element: (
                <RouteComponent>
                  <AssetSearch />
                </RouteComponent>
              ),
            },
            {
              path: "sources",
              element: (
                <RouteComponent>
                  <SourcePage />
                </RouteComponent>
              ),
              children: [
                { index: true, element: <Navigate to="generic-csv" /> },
                {
                  element: (
                    <RouteComponent>
                      <SourceList />
                    </RouteComponent>
                  ),
                  children: [
                    {
                      index: true,
                      path: "generic-csv",
                      element: (
                        <RouteComponent>
                          <SourceOption
                            sourceType={FileRequestSourceType.SUBPOENA_INTERNAL}
                          />
                        </RouteComponent>
                      ),
                    },
                    ...(shouldShowJSONLUpload
                      ? [
                          {
                            path: "jsonl",
                            element: (
                              <RouteComponent>
                                <SourceOption
                                  sourceType={FileRequestSourceType.JSONL}
                                />
                              </RouteComponent>
                            ),
                          },
                        ]
                      : []),
                    ...(isWalletUploadEnabled
                      ? [
                          {
                            path: "public-address",
                            element: (
                              <RouteComponent>
                                <SourceOption
                                  sourceType={FileRequestSourceType.WALLET}
                                />
                              </RouteComponent>
                            ),
                          },
                        ]
                      : []),
                  ],
                },
                {
                  path: ":sourceId",
                  element: (
                    <RouteComponent>
                      <SourceErrors />
                    </RouteComponent>
                  ),
                },
                {
                  path: "add",
                  element: (
                    <RouteComponent>
                      <AddSource />
                    </RouteComponent>
                  ),
                  children: [
                    {
                      index: true,
                      path: "generic-csv",
                      element: (
                        <RouteComponent>
                          <GenericCsv />
                        </RouteComponent>
                      ),
                    },
                    {
                      path: "jsonl",
                      element: (
                        <RouteComponent>
                          <JsonLFile />
                        </RouteComponent>
                      ),
                    },
                    ...(isWalletUploadEnabled
                      ? [
                          {
                            path: "public-address",
                            element: (
                              <RouteComponent>
                                <PublicAddress />
                              </RouteComponent>
                            ),
                          },
                        ]
                      : []),
                  ],
                },
                {
                  path: "column-formatting-errors",
                  element: (
                    <RouteComponent>
                      <ColumnFormattingErrors />
                    </RouteComponent>
                  ),
                },
                {
                  path: "wallet-sync-errors",
                  element: (
                    <RouteComponent>
                      <ViewWalletErrors />
                    </RouteComponent>
                  ),
                },
              ],
            },
            ...(isFileConverterEnabled
              ? [
                  {
                    path: "file-converter",
                    children: [
                      { index: true, element: <Navigate to="upload" /> },
                      {
                        path: "upload",
                        element: (
                          <RouteComponent>
                            <FileConverterUploadPage />
                          </RouteComponent>
                        ),
                      },
                      {
                        path: "review",
                        element: (
                          <RouteComponent>
                            <FileConverterReviewPage />
                          </RouteComponent>
                        ),
                      },
                    ],
                  },
                ]
              : []),
            {
              path: "case-settings",
              element: (
                <RouteComponent>
                  <CaseSettingView />
                </RouteComponent>
              ),
            },
            {
              path: "link-transactions",
              element: (
                <RouteComponent>
                  <LinkTransactions />
                </RouteComponent>
              ),
            },
          ],
        },
        {
          path: "*",
          element: (
            <RouteComponent>
              <NotFound />
            </RouteComponent>
          ),
          errorElement: (
            <RouteComponent>
              <ErrorPage />
            </RouteComponent>
          ),
        },
      ],
    },
  ]);

  return (
    <AppWrapper>
      <Suspense fallback={<ContentSpinner trackingId="fallback-spinner" />}>
        <CaseFilterQueryParamsCacheProvider>
          <CiTermsOfUseWrapper>
            <RouterProvider router={router} />
          </CiTermsOfUseWrapper>
        </CaseFilterQueryParamsCacheProvider>
      </Suspense>
    </AppWrapper>
  );
};

const AppWrapper = styled.div(
  ({ theme }) => `
  background-color: ${theme.color.gray0};
  height: 100vh;
  width:100vw;
  overflow: auto;
`
);
