// A flag file to help more easily toggle feature deploys
// where contributions span across multiple PRs

import useDoesEmailMatch from "./useDoesEmailMatch";
import useTenantIdDoesMatch from "./useTenantIdDoesMatch";

const DACS_DEMO_TENANT_IDS = new Set([
  "tenant_d9397751-2c42-4940-9839-42a4a3e5ac1c",
]);

const CONVERTED_FILES_ACCESS_EMAILS = new Set(["momin.chaudhry@taxbit.com"]);

export const useFlags = () => {
  const isDacsClient = useTenantIdDoesMatch(DACS_DEMO_TENANT_IDS);
  const hasConvertedFilesAccess = useDoesEmailMatch(
    CONVERTED_FILES_ACCESS_EMAILS
  );

  return {
    isCustomConverterEnabled: true, // controls the Settings Page custom converter experiment
    isAssignmentEnabled: true, // controls the assignment UI
    isMultipartUploadEnabled: true, // controls the multipart upload UI
    isCaseSummaryEnabled: true, // controls the case summary UI
    isDacsClient, // controls the DACS Clients UI
    shouldHideNestedIntegrations: true, // controls whether nested integrations are hidden
    isAssetMappingEnabled: true, // controls the asset mapping UI
    isMultipleWalletUploadEnabled: true, // controls the multiple wallet upload UI
    shouldShowJSONLUpload: false, // shows the JSONL file upload UI
    isWalletUploadEnabled: true,
    isFileConverterEnabled: hasConvertedFilesAccess,
  };
};
